
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Modules
@import "node_modules/sweetalert/dev/sweetalert.scss";

// Custom
.form-payment-methods {
  float: left;
  height: 25px;
}

.btn-subscribe {
  float: right;
}

.panel-table {
  margin-bottom: 0;

  ul {
    padding-left: 15px;
  }

  & > tbody > tr:first-child {
    td, th {
      border: none;
    }
  }
}

.well-transparent {
  margin-top: 20px;
  border: none;
  background: none;
  box-shadow: none;
}

.panel-title {
  padding: 10px 0;

  .btn {
    margin-top: -10px;
  }
}

@media all and (max-width: $screen-xs-max) {
  .btn-block-xs {
    width: 100%;
    display: block;
  }
  .btn-subscribe {
    float: none;
  }
  .form-payment-methods {
    float: none;
    margin-top: 10px;
  }
  .nav-stack-on-xs {
    width: 100%;
    text-align: center;
    & > div {
      width: 100%;
    }
  }
}